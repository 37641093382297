/**
 * Convert array of strings into a single space delimited string
 *
 * TODO: add custom `join` or use in conjunction with `filter(Boolean)` to remove falsy values like empty strings.
 * Make sure to think about the implications of iterating over the same array twice before taking the later route,
 * perhaps, unimportant.
 */
const classList = (arr: string[]) => arr.join(' ');

/**
 * `extendClassList` adds the ability to extend the `className` property on any given React Element that accepts a `className`
 * property. It specifically extends the `className` property with the ability to either "extend" the base styles provided
 * with an new styles OR clobber them entirely (the nuclear option). By passing this into the `className` property of a component,
 * you automatically get this behavior.
 *
 * @usage (clobber default styles):
 * <Anchor className={extendClassList('uic-text-12')} />
 *
 * @usage (extend default styles):
 * <Anchor className={extendClassList((styles) => `${styles} uic-text-12`, 'uic-font-bold')} />
 */
function extendClassList(className: string | ((cl?: string) => string), cl: string = '') {
	if (className && typeof className === 'function') {
		// Extend class list
		return className(cl);
	}

	// Clobber class list (we trust you :wink:)
	return className || cl;
}

/**
 * Convert to rem unit
 */
const rem = (target: number[] | number, base: number = 16) => {
	const format = (item: number) => (`${item / base}rem`);

	if (Array.isArray(target)) {
		return [...target].map((item) => format(item)).join(' ');
	}

	return format(target);
};

const helpers = {
	classList,
	extendClassList,
	rem,
};

export {
	helpers as default, classList, extendClassList, rem,
};
